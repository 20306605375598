import React from "react";

const About = () => {
  return (
    <>
      <div id="About">
        <h1>This Is where you get more information about me</h1>
      </div>
    </>
  );
};

export default About;
